import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import styles from "./styles.module.scss";
import { TFunction, useTranslation } from "src/i18n";

let t: TFunction;

const ToastsComponent = () => {
  t = useTranslation().t;
  return <ToastContainer />;
};

export const toastApi = {
  toast,
  showError: (msg = "common_errors.error_occured") => toast<string>(t(msg), { type: "error", className: styles.error_toast }),
  showInfo: (msg: string) => toast<string>(t(msg), { type: "info" }),
};

export const useToasts = () => toastApi;
export default ToastsComponent;